import React, { useState, useEffect } from "react";
import posed, { PoseGroup } from "react-pose";
import "./home.css";
import slide1 from '../Assets/slide1.jpeg';
import slide2 from '../Assets/slide2.jpg';
import slide3 from '../Assets/slide3.jpg';
import top from '../Assets/icons8-collapse-arrow-50.png';
import whatsapp from '../Assets/whatsapp.png';
import { Link } from 'react-scroll';

const Modal = posed.div({
    enter: {
        x: 0,
        opacity: 1,
        transition: { x: { type: "tween", from: "0%", to: "0%", duration: 100 }, opacity: { duration: 300 } }
    },
    exit: {
        x: "-100%",
        opacity: 0,
        transition: { x: { type: "tween", from: "0%", to: "-0%", duration: 100 }, opacity: { duration: 150 } }
    },
});

export const Home = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [slides, setSlides] = useState([
        { title: "BIENVENUE CHEZ", text: "SOLA BEAT", background: slide3, buttonText: "" },
        { title: "SOLUTION DE TECHNOLOGIE", text: "POUR LES ENTREPRISES", background: slide2, buttonText: "Contactez-Nous" },
        { title: "ALL IN", text: "ONE", background: slide1, buttonText: "Demandez un devis" },
    ]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            changeSlide();
        }, 5000);
        return () => clearInterval(interval); // Cleanup function to clear interval
    }, [currentSlide]); // Run effect whenever currentSlide changes

    // const nextSlide = () => {
    //     const nextSlideIndex = currentSlide === slides.length - 1 ? 0 : currentSlide + 1;
    //     setIsVisible(false);
    //     setCurrentSlide(nextSlideIndex);
    //     setTimeout(() => {
    //         setIsVisible(true);
    //     }, 500);
    // };

    // const prevSlide = () => {
    //     const prevSlideIndex = currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
    //     setIsVisible(false);
    //     setCurrentSlide(prevSlideIndex);
    //     setTimeout(() => {
    //         setIsVisible(true);
    //     }, 500);
    // };

    const changeSlide = (next = true) => {
        const nextSlideIndex = next ? (currentSlide === slides.length - 1 ? 0 : currentSlide + 1) : (currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
        setIsVisible(false);
        setCurrentSlide(nextSlideIndex);
        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    };

    const handleLinkClick = () => {
        // Handle link click logic
    };

    return (
        <div className="h-screen relative  w-screen text-[32px] font-[200] flex bg-center bg-cover bg-no-repeat justify-center items-center" style={{
            // backgroundImage: `url(${slides[currentSlide].background})`,
            top: 0,
            transition: 'left 1s ease-in-out',
            right: 0,
            left: 0,
            zIndex: 0,
        }}>
            <img src={`${slides[currentSlide].background}`} className="object-cover w-full h-full" alt="" />
            <div className="overlay"></div>
            <PoseGroup >
                {isVisible && (
                    <Modal key="modal" className="modal w-screen  flex flex-col justify-center items-center h-screen z-50 left-0 right-0 top-0 absolute">
                        <div className="content flex flex-col justify-center items-center " style={{ zIndex: 1 }}>
                            <p className="slide text-white font-bold text-3xl text-center md:text-6xl  mb-3">{slides[currentSlide].title}</p>
                            <h1 className="slide1">
                                <p className="text-2xl md:text-5xl text-[#db6f15] text-center font-semibold">{slides[currentSlide].text}</p>
                            </h1>
                            <Link
                                to={"/contact"}
                                spy={true}
                                smooth={true}
                                duration={500}
                            // onClick={handleLinkClick}
                            >
                                {currentSlide !== 0 && (
                                    <button className={`slide2 mt-12 border md:text-base font-bold text-sm py-3 px-10 md:px-24 rounded-lg animated-button`}>
                                        {slides[currentSlide].buttonText}
                                    </button>
                                )}
                            </Link>

                        </div>
                    </Modal>
                )}
            </PoseGroup>
            <a href="https://wa.me/+212653030413" target='_blank'>
                <button className="bg-gray-300 p-1 rounded-full bottom-16 right-4 fixed">
                    <img src={whatsapp} width={40} alt="Top" />
                </button>
            </a>
            <Link
                to={"/"}
                spy={true}
                smooth={true}
                duration={500}
                onClick={handleLinkClick}
            >
                <button className="bg-gray-300 p-3 rounded-full bottom-3 right-4 fixed">
                    <img src={top} width={20} alt="Top" />
                </button>
            </Link>
        </div>
    );
};
